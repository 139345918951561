import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Images from '../../assets';


const AnimationStartWebsite = ({ onClick }) => {

  return (
    <>
      {/* <div
        style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
        className="py-20 backdrop-filter h-screen backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 "
      >
        <div className="modals relative bg-white w-full max-w-8xl h-full rounded-2xl zoom_in_pp_modal ">
          <div className=" bg-white rounded-2xl shadow-lg  p-6 max-sm:px-3">
            <div className='relative bg-white rounded-2xl h-full'>
              <img
                src={Images.location_poster}
                alt=''
                width="100%"
                height="100%"
                className=' inset-0 w-full h-full object-cover z-0 rounded-2xl'
              />
              <div className='absolute z-10  inset-0'>
                <div className={`border border-green-500 absolute bottom-0 z-10 h-full inset-0 flex items-end  pb-20 ps-20 mx-auto max-w-full max-md:ps-5 max-md:pb-5`}>
                  <div className='  flex items-end max-md:flex-col max-md:gap-5 max-md:px-5'>
                    <div className='w-full max-md:w-full flex items-center flex-col  justify-center'>
                      <div className='text-orange-500 font-bold text-7xl leading-normal'
                        style={{
                          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                          WebkitTextStroke: '0.5px white',
                        }}
                      >
                        WE ARE <br></br>MOVING TO<br></br> OUR NEW PREMISES
                      </div>
                      <div className=' w-full'>
                        <Link to={'/'}
                          onClick={onClick}
                        >
                          <div className="w-fit px-5 py-2.5 mt-6 text-base text-white bg-orange-500 rounded-lg cursor-pointer " >
                            Get Started
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='w-[50%] max-md:w-full gap-2 flex items-end justify-end h-full'>
                <div className='flex  gap-3 items-center justify-center h-full'>
                  <div className="mt-2.5 text-base text-zinc-600 font-medium flex cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('https://www.google.com/maps/dir//101-Shree+Kashi+Parekh+Complex,+First+Floor,,+Behind+Bhagavati+Chambers,+Near+Swastik+Cross+Rd,,+C+G+Road,+Navrangpura,,+Ahmedabad,+Gujarat+380009/@23.0326279,72.5119498,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395e85041941d8fb:0xb2153be6312e2c74!2m2!1d72.5624224!2d23.0377044?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D', '_blank');
                    }}
                  >
                    <img
                      src={Images.home_location_pin}
                      alt=''
                      className='w-6 '
                    />
                  </div>
                  <div className='flex flex-col uppercase'>
                    <div className='text-xs'>
                      To :
                    </div>
                    <div className='font-bold text-base '>
                      Headway Business Solutions LLP
                    </div>
                  </div>
                </div>
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
        className=" backdrop-filter h-full  backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full md:inset-0 "
      >
        <div className='modals h-full max-md:overflow-y-auto flex items-center max-md:items-start zoom_in_pp_modal'>
          <div className=' relative w-full p-14  max-w-8xl  rounded-2xl flex items-end mx-auto max-md:ps-5 max-md:pb-5'
            style={{ backgroundColor: '#FFF9F4' }}
          >
            <div className=' justify-between  flex  max-lg:flex-col max-lg:gap-5 max-lg:px-5 '>
              <div className='w-full max-lg:w-full flex items-start flex-col justify-between gap-5'>
                <img
                  src={Images.logo}
                  alt=''
                  width="20%"
                  height=""
                  className=""
                />
                <div>
                  <div className='text-orange-500 font-bold xl:text-6xl xl:leading-normal 2xl:text-6xl  2xl:leading-normal lg:text-5xl lg:leading-normal md:text-3xl md:leading-normal  max-sm:text-4xl sm:leading-normal'
                    style={{
                      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                      WebkitTextStroke: '0.5px white',
                    }}
                  >
                    WE ARE <br></br>MOVING TO<br></br> OUR NEW PREMISES
                  </div>
                  <div className=' w-full'>
                    <Link to={'/'}
                      onClick={onClick}
                    >
                      <div className="w-fit px-5 py-2.5 mt-6 text-base text-white bg-orange-500 rounded-lg cursor-pointer " >
                        Get Started
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='w-full flex flex-col items-end '>
                <div className=' w-[50%] cursor-pointer shadow p-3 rounded-xl max-md:w-full'
                  style={{ backgroundColor: '#FFF9F4' }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.google.com/maps/place/Headway+Business+Solutions+LLP/@23.0328503,72.5637577,1640m/data=!3m1!1e3!4m14!1m7!3m6!1s0x395e85041941d8fb:0xb2153be6312e2c74!2sHeadway+Business+Solutions+LLP!8m2!3d23.0377044!4d72.5624224!16s%2Fg%2F11dxbdc4q2!3m5!1s0x395e85041941d8fb:0xb2153be6312e2c74!8m2!3d23.0377044!4d72.5624224!16s%2Fg%2F11dxbdc4q2?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D', '_blank');
                  }}
                >
                  <div className='text-orange-500 font-bold text-xl'>Headway Business Solutions LLP</div>
                  <div className='text-neutral-500 text-sm mt-2'>
                    101-Shree Kashi Parekh Complex, First Floor, Behind Bhagavati Chambers, Near Swastik Cross Rd,
                    C G Road, Navrangpura, Ahmedabad, Gujarat 380009
                  </div>
                </div>
                <img
                  src={Images.new_poster_map}
                  alt=''
                  width=""
                  height=""
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnimationStartWebsite