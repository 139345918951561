import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './component/Home';
import Layout from './component/Layout';
import AboutPage from './component/AboutPage';
import GetInTouch from './component/GetInTouch';
import MakeMyBusiness from './component/MakeMyBusiness';
import PlanPricing from './component/PlanPricing';
import StartUp from './component/StartUp';
import LoginWithSociallMedia from './component/UserAuth/Login/LoginWithSocialMedia';
import LoginWithEmail from './component/UserAuth/Login/LoginWithEmail';
import RegisterWithSocialMedia from './component/UserAuth/Register/RegisterWithSocialMedia';
import RegisterWithEmail from './component/UserAuth/Register/RegisterWithEmail';
import Verification from './component/UserAuth/Verification';
import BlogPage from './component/BlogPage';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermsCondition from './component/Terms&Condition';
import OurServices from './component/OurServices';
import RefundCancellation from './component/RefundCancellation';
import MeetOurTeam from './component/MeetOurTeam';
import JewelleryVidhyapithPage from './component/JewelleryVidhyapithPage';
import HeadwayIT from './component/HeadwayIT';
import Gallery from './component/Gallery';
import OurCourses from './component/OurCourses';
import EventMainPage from './component/EventMainPage';
import EventDetailPage from './component/EventDetailPage';
import IDBPage from './component/IDBPage';
import IntelligentHR from './component/IntelligentHR';
import HeadwayInitiatives from './component/HeadwayInitiatives';
import UserProfile from './component/LoggedInUser/UserProfile';
import { useEffect, useState } from 'react';
import './firebaseConfig';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import WebPage from './component/Webpage';
import AnimationStartWebsite from './component/AnimationStartWebsite';
import BlogDetailPage from './component/BlogPage/BlogDetailPage';
const firebaseConfig = {
  apiKey: "AIzaSyBVxwIRObCoOvDeQ-R9FUJfevwgSRZtBDg",
  authDomain: "headwaywebsite-da968.firebaseapp.com",
  projectId: "headwaywebsite-da968",
  storageBucket: "headwaywebsite-da968.appspot.com",
  messagingSenderId: "265131377167",
  appId: "1:265131377167:web:665a27cbd9e89c6048997a",
  measurementId: "G-HSEMWE2PRZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  // const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  // const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    logEvent(analytics, "testing_firebase_analytics")
  }, [])


  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     if (window.orientation === 90 || window.orientation === -90) {
  //       setIsLandscape(true);
  //     } else {
  //       setIsLandscape(false);
  //     }
  //   };

  //   window.addEventListener('orientationchange', handleOrientationChange);

  //   return () => {
  //     window.removeEventListener('orientationchange', handleOrientationChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     setIsPortrait(window.innerHeight > window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleOrientationChange);

  //   return () => {
  //     window.removeEventListener('resize', handleOrientationChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (window.screen.orientation) {
  //     window.screen.orientation.lock('portrait');
  //   }
  // }, []);

  // useEffect(() => {

  //   const handleOrientationChange = () => {
  //     if (window.orientation === 90 || window.orientation === -90) {
  //       document.body.style.transform = 'rotate(0deg)';
  //     }
  //   };

  //   window.addEventListener('orientationchange', handleOrientationChange);

  //   return () => {
  //     window.removeEventListener('orientationchange', handleOrientationChange);
  //   };
  // }, []);


  return (
    <>

      {/* {isLandscape ? (
        <div>
          <h2 style={{ color: 'black', textAlign: 'center' }}>Please turn your device to portrait mode</h2>
        </div>
      )
        :
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about_us" element={<AboutPage />} />
          <Route path="/get_in_touch" element={<GetInTouch />} />
          <Route path="/make_my_business" element={<MakeMyBusiness />} />
          <Route path="/plan_pricing" element={<PlanPricing />} />
          <Route path="/start_up" element={<StartUp />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_and_conditions" element={<TermsCondition />} />
          <Route path="/refund_and_cancellation" element={<RefundCancellation />} />
          <Route path="/our_services" element={<OurServices />} />
          <Route path="/meet_our_team" element={<MeetOurTeam />} />
          <Route path="/jewellery_vidhyapith" element={<JewelleryVidhyapithPage />} />
          <Route path="/headway_IT" element={<HeadwayIT />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/our_courses" element={<OurCourses />} />
          <Route path="/event_main" element={<EventMainPage />} />
          <Route path="/event_detail" element={<EventDetailPage />} />
          <Route path="/idb" element={<IDBPage />} />
          <Route path="/intelligent_hr" element={<IntelligentHR />} />
          <Route path="/headway_initiatives" element={<HeadwayInitiatives />} />
          <Route path="/login_with_social_media" element={<LoginWithSociallMedia />} />
          <Route path="/login_with_email" element={<LoginWithEmail />} />
          <Route path="/register_with_social_media" element={<RegisterWithSocialMedia />} />
          <Route path="/login" element={<RegisterWithEmail />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/user_profile" element={<UserProfile />} />
          <Route
          path="/webpage"
          element={<WebPage
            title="Headway Business Solution"
            description="A brief description of my website."

          />} />
        </Routes>

      } */}

      {/* <FirebaseAnalyticsProvider firebaseAnalytics={analytics}> */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<AnimationStartWebsite />} /> */}
        <Route path="/about_us" element={<AboutPage />} />
        <Route path="/get_in_touch" element={<GetInTouch />} />
        <Route path="/make_my_business" element={<MakeMyBusiness />} />
        <Route path="/plan_pricing" element={<PlanPricing />} />
        <Route path="/start_up" element={<StartUp />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blog_detail" element={<BlogDetailPage />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_and_conditions" element={<TermsCondition />} />
        <Route path="/refund_and_cancellation" element={<RefundCancellation />} />
        <Route path="/our_services" element={<OurServices />} />
        <Route path="/meet_our_team" element={<MeetOurTeam />} />
        <Route path="/jewellery_vidhyapith" element={<JewelleryVidhyapithPage />} />
        <Route path="/headway_IT" element={<HeadwayIT />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/our_courses" element={<OurCourses />} />
        <Route path="/event_main" element={<EventMainPage />} />
        <Route path="/event_detail" element={<EventDetailPage />} />
        <Route path="/idb" element={<IDBPage />} />
        <Route path="/intelligent_hr" element={<IntelligentHR />} />
        <Route path="/headway_initiatives" element={<HeadwayInitiatives />} />
        <Route path="/login_with_social_media" element={<LoginWithSociallMedia />} />
        <Route path="/login_with_email" element={<LoginWithEmail />} />
        <Route path="/register_with_social_media" element={<RegisterWithSocialMedia />} />
        <Route path="/login" element={<RegisterWithEmail />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/user_profile" element={<UserProfile />} />
        {/* <Route
            path="/webpage"
            element={<WebPage
              title="Headway Business Solution"
              description="A brief description of my website."

            />} /> */}
      </Routes>
      {/* </FirebaseAnalyticsProvider> */}
    </>
  );
}

export default App;
