import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Images from '../../../assets';
import { fetchData } from '../../../apiGeneralFunction';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDrag } from 'react-use-gesture';
import { Gallery, GalleryImage } from "react-gesture-gallery";
import 'react-image-gallery/styles/css/image-gallery.css'; 



const HomeBanner = ({ data }) => {


    let sliderRef = useRef(null);
    const [index, setIndex] = useState(0);
    // console.log("home banner list", data);


    const images = [
        // `${Images.Home_banner1_cmp}`,
        // `${Images.Home_banner2_cmp}`,
        // `${Images.Home_banner3_cmp}`,
        `${Images.Home_banner1}`,
        `${Images.Home_banner2}`,
        `${Images.Home_banner3}`,
    ];

    const settings = {
        className: "center",

        infinite: true,

        autoplay: true,
        slidesToShow: 1,
        speed: 500,
        autoplaySpeed: 4000,
        cssEase: "linear",
        fade: true,

        swipeToSlide: true,
        ltr: true,

        afterChange: function (index) {
            // console.log(
            //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            // );
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,


                }
            }
        ]
    };

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const ImagesData = [
        {
            src: `${Images.Home_banner1}`,
            thumbnail: 'https://example.com/thumbnail1.jpg',
            description: 'Image 1 Description',
        },
        {
            src: `${Images.hero_section_my_bzb}`,
            thumbnail: 'https://example.com/thumbnail2.jpg',
            description: 'Image 2 Description',
        },
        {
            src: `${Images.Home_banner3}`,
            thumbnail: 'https://example.com/thumbnail2.jpg',
            description: 'Image 2 Description',
        },
        // Add more image objects as needed
    ];

    return (
        <>


            {/* <section className="  flex justify-center items-center px-16 max-md:px-5 bg-white static"
            > */}
               
                <div 
                // className="flex flex-col max-w-full w-[1296px] border border-green-500 "
                className="max-w-full w-[1296px] pt-10 mx-auto"
                >
                        <Gallery
                            index={index}
                            onRequestChange={i => {
                                setIndex(i);
                            }}
                            //  renderCustomControls={renderCustomControls}
                        >
                            {data && data?.length > 0 ? (
                                data.map((img,index) => (
                                    <GalleryImage objectFit="contain" key={index} src={img.image} />
                                ))
                            ) : (
                                ImagesData.map((img,index) => (
                                    <GalleryImage objectFit="contain" key={index} src={img.src} />
                                ))  
                            )
                            } 

                        </Gallery>

                </div>

            {/* </section> */}
           
         
        </>
    )
}

export default HomeBanner